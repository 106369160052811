import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import RouterTracker from "./RouteTracker";
import MainSearchContext from "../../context/MainSearchContext";
import ErrorBoundary from "../ErrorBoundary";
import CreatePopup from "../Header/CreatePopup";

const MainContainer = () => {
  const { sideMenu } = useSelector((state) => state.settings);
  return (
    <>
      <MainSearchContext>
        <CreatePopup>
          {/* <Sidebar /> */}

          {/* <main
            className={
              !sideMenu
                ? " transition-all lg:ml-[64px]"
                : " transition-all  lg:ml-[240px] lg:mr-[0px]"
            }
          > */}
          <RouterTracker />
          <main className="transition-all relative">
            <ErrorBoundary>
              <Header />
              <Outlet />
            </ErrorBoundary>
          </main>
        </CreatePopup>
      </MainSearchContext>
    </>
  );
};

export default MainContainer;
