import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./reactQuery/queryClient";
import { ChakraProvider } from "@chakra-ui/react";
import AppRoute from "./AppRoutes";
import MainContainer from "./components/MainContainer/MainContainer";
import CacheBuster from "react-cache-buster";
import Loader from "./components/Loader/Loader";
import AppProvider from "./components/UserAuth/AppProvider";
import ErrorContextNotification from "./components/ErrorAlert/ErrorContextNotification";

export default function App() {
  const [appVersion, setAppVersion] = useState("");

  useEffect(() => {
    fetch("/version.json")
      .then((res) => res.json())
      .then((data) => setAppVersion(data.version))
      .catch((error) => {
        console.error("Error fetching version.json:", error);
      });
  }, []);

  return (
    <>
      <AppProvider>
        <CacheBuster
          currentVersion={appVersion}
          isEnabled={true}
          isVerboseMode={false}
          loadingComponent={<Loader />}
          metaFileDirectory={"."}
          metaFileName="version.json"
        >
          {/* <ChakraProvider> */}
          <ErrorContextNotification>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <MainContainer />
              </Provider>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </QueryClientProvider>
          </ErrorContextNotification>
          {/* </ChakraProvider> */}
        </CacheBuster>
      </AppProvider>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={AppRoute} />);
