import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { MaterialIcon } from "material-icons";
import { useDispatch, useSelector } from "react-redux";
import ErrorToast from "../utils/ErrorToast";
import { FREEBIES_EXPIRE_DAYS, logout_API } from "../../constants";
import SessionOut from "../SessionOut/SessionOut";
// import { mobileMenu } from "../../Redux/Action/Settings";
import { useRef } from "react";
import service from "../../Services/auth.service";
import Searchbar from "./Searchbar";
import { USERINFO } from "../../constants";
import userService from "../../Services/user.service";
import logo from "../assets/skribe-logo.png";
import TawkMessenger from "../Chat/TawkMessenger";
import tokenService from "../../Services/token.service";
import Tooltip from "../Tooltip/Tooltip";
import LockTooltip from "../Tooltip/LockTooltip";
import LockTooktip from "../Tooltip/LockTooltip";
import HeaderLock from "../Tooltip/HeaderLock";
import MorePower from "./MorePower";
import { usePopup } from "./CreatePopup";
import { useEditorState } from "react-simple-wysiwyg";
import ErrorAlerts from "../ErrorAlert/ErrorAlerts";

const Header = () => {
  const openDD = useRef();
  const closeDD = useRef();
  const openDD1 = useRef();
  const closeDD1 = useRef();
  const [socialDropDown, setSocialDropDown] = useState(false);
  const [campaignDropDown, setCampaignDropDown] = useState(false);

  const dispatch = useDispatch();
  const [warningactive, warningSetActive] = useState(false);
  const [erroractive, errorSetActive] = useState(false);
  const [successactive, successSetActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [roleType, setRoleType] = useState(tokenService.getLocalRole());
  const [remaingDays, setRemainingDays] = useState(0);
  const location = useLocation();
  const { showPopup } = usePopup();
  // const errorAlert = useSelector((state) => state.settings.errorAlert);
  // const warningAlert = useSelector((state) => state.settings.warningAlert);
  // const successAlert = useSelector((state) => state.settings.successAlert);
  // let roleType = tokenService.getLocalRole();
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     localStorage.clear();
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  const FreeDays = async () => {
    try {
      let response = userService.get(FREEBIES_EXPIRE_DAYS);
      if (response?.response?.status === "Ok") {
        setRemainingDays(response?.remainingDays || 0);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (roleType?.includes("Freebies")) {
      FreeDays();
    }
    if (remaingDays === 0) {
      //showPopup();
    }
  }, [roleType, remaingDays]); // Dependency array: re-run if roleType changes

  const mobileView = () => {
    setMobileMenu(true);
  };
  const mainNav = [
    {
      name: "Home",
      //url: "journalist-search",
      url: "dashboard",
    },

    {
      name: "Brand",
      url: "brandDashboard",
    },

    {
      name: "Social",
      url: "",
      children: [
        {
          name: "X",
          url: "X",
        },
        {
          name: "Instagram",
          url: "instagram",
          // url: "insta-detail",
        },
        {
          name: "Podcast",
          url: "podcast",
        },
      ],
    },
    {
      name: "Campaigns",
      url: "",
      children: [
        {
          name: "Create Campaign",
          url: "campaigns",
          // url: "insta-detail",
        },
        {
          name: "Campaign Tracker",
          url: "campaign-summary",
        },
        {
          name: "Media Lists ",
          url: "campaign-manager",
        },
      ],
    },

    {
      name: "MyCRM",
      url: "mycrm",
    },
    // {
    //   name: "Campaigns",
    //   url: "campaign-manager",
    // },
  ];

  const applogout = async () => {
    await userService
      .post(`Authenticate/logout`, "")
      .then((res) => {})
      .catch(() => {});
    localStorage.clear();
    service.logout();
    window.location.href = "/";
  };

  useEffect(() => {
    window.addEventListener("click", outSide);
    return () => {
      document.removeEventListener("click", outSide);
    };
  }, []);
  const outSide = (e) => {
    if (openDD.current && !openDD.current.contains(e.target)) {
      setSocialDropDown(false); // Close dropdown if clicked outside
    }

    if (openDD1.current && !openDD1.current.contains(e.target)) {
      setCampaignDropDown(false); // Close dropdown if clicked outside
    }
    // if (closeDD.current && !closeDD.current.contains(e.target)) {
    //   setSocialDropDown(true);
    // }
  };

  const postTracking = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const Url = location.pathname.split("/");
    const pageName = Url[1] || "home";
    let pageId;
    if (
      [
        "journalistByOutlet",
        "OutletEditor",
        "BureauChief",
        "Columnist",
        "Supplements",
        "International",
      ].includes(pageName)
    ) {
      pageId = Url[3] || "";
    } else {
      pageId = Url[2] || "";
    }
    const trackingData = {
      userId: userInfo?.id,
      pageName: pageName,
      clickId: pageId,
    };
    userService
      .post(`PostTracking`, trackingData)
      .then((res) => {
        console.log("Tracking data posted successfully");
      })
      .catch((error) => {
        console.log(error, "Error posting tracking data");
      });
  };
  useEffect(() => {
    postTracking();
  }, [location.pathname]);

  return (
    <>
      <TawkMessenger />
      <ErrorAlerts msg="testing" />
      {roleType?.includes("Freebies") && (
        <p className="bg-red-500 text-white text-sm py-2 z-50 fixed text-center bottom-0 left-0 right-0">
          {`your trial period is about to end, To continue enjoying uninterrupted access, please consider upgrading your account. your account left days ${remaingDays}/30`}
        </p>
      )}
      {/* <SessionOut /> */}
      {!isOnline && <ErrorToast msg="It seems your Internet is not working" />}
      {/* {errorAlert.msg && erroractive && <ErrorToast msg={errorAlert.msg} />}
      {warningAlert.msg && warningactive && (
        <WarningToast msg={warningAlert.msg} />
      )}
      {successAlert.msg && successactive && (
        <SuccessToast msg={successAlert.msg} />
      )} */}
      <header
        className={`${
          mobileMenu ? "absolutes" : "sticky"
        }  top-0 z-50 flex flex-col-reverse justify-between border-b  border-gray-300 bg-[#6521AD] p-3 md:flex-row md:items-center lg:flex-row`}
      >
        <Searchbar />
        <div className="flex items-center justify-between mb-4 gap-x-5 md:mb-0">
          <div className="flex flex-row gap-2">
            <Link onClick={() => mobileView()} className="lg:hidden">
              <span className="px-3 py-2 text-lg text-white border border-white rounded-lg material-icons-outlined hover:text-white">
                menu
              </span>
            </Link>
            <div className="pr-2 lg:hidden">
              <Link to="/dashboard">
                <img src={logo} alt="image" width="80" />
              </Link>
            </div>
          </div>

          <ul
            role="Primary Navigation"
            aria-label="Main Navigation"
            className={`${
              mobileMenu
                ? "fixed block z-[70] inset-0 p-10 bg-[#6521AD]"
                : "hidden"
            }  pb-5 gap-x-5 md:pb-0 lg:flex items-center text-sm`}
          >
            {mobileMenu && (
              <>
                <span
                  onClick={() => setMobileMenu(!mobileMenu)}
                  className="material-icons-outlined text-white absolute top-10 right-10"
                >
                  close
                </span>
                <h3 className="text-white text-xl font-medium border-b border-gray-500 pb-1 mb-2">
                  Main Menu
                </h3>
              </>
            )}{" "}
            {mainNav.map((curElem, index) => (
              <React.Fragment key={index}>
                {curElem?.name === "Social" ? (
                  <li className="relative pb-4 lg:pb-0">
                    <span
                      ref={openDD}
                      className="flex items-center text-white cursor-pointer gap-x-1"
                      onClick={() => setSocialDropDown(!socialDropDown)}
                    >
                      {curElem.name}
                      {curElem?.children?.length > 0 && (
                        <span
                          className={
                            socialDropDown
                              ? "material-icons-outlined icon-16 rotate-180 transition-all duration-500"
                              : "material-icons-outlined icon-16 -rotate-360 transition-all duration-500"
                          }
                        >
                          expand_more
                        </span>
                      )}
                    </span>
                    {socialDropDown && curElem?.name === "Social" && (
                      <ul
                        ref={closeDD}
                        className={`${
                          mobileMenu ? "static" : "absolute bg-[#6521AD] mt-2"
                        } -left-1 w-32  shadow-2xl`}
                      >
                        {curElem?.children?.map((childElem, childIndex) => (
                          <li key={childIndex}>
                            <Link
                              className="block px-2 py-1 text-white hover:bg-[#591b9b]"
                              to={childElem.url}
                              onClick={() =>
                                mobileMenu && setMobileMenu(!mobileMenu)
                              }
                            >
                              {childElem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : curElem?.name === "Campaigns" ? (
                  <li className="relative pb-4 lg:pb-0">
                    <span
                      ref={openDD1}
                      className="flex items-center text-white cursor-pointer gap-x-1"
                      onClick={() => setCampaignDropDown(!campaignDropDown)}
                    >
                      {curElem.name}
                      {curElem?.children?.length > 0 && (
                        <>
                          <span
                            className={
                              campaignDropDown
                                ? "material-icons-outlined icon-16 rotate-180 transition-all duration-500"
                                : "material-icons-outlined icon-16 -rotate-360 transition-all duration-500"
                            }
                          >
                            expand_more
                          </span>
                          <span className="absolute right-0 -top-4 text-[11px] bg-white text-gray-600 rounded-lg px-1 py-0 leading-4">
                            Beta
                          </span>
                        </>
                      )}
                    </span>
                    {campaignDropDown && curElem?.name === "Campaigns" && (
                      <ul
                        ref={closeDD1}
                        className={`${
                          mobileMenu ? "static" : "absolute mt-2 bg-[#6521AD]"
                        } -left-1  w-44  shadow-2xl text-sm`}
                      >
                        {curElem?.children?.map((childElem, childIndex) => (
                          <li key={childIndex}>
                            <Link
                              className="flex gap-x-2 px-2 py-1 text-white hover:bg-[#591b9b]"
                              to={childElem.url}
                              onClick={() =>
                                mobileMenu && setMobileMenu(!mobileMenu)
                              }
                            >
                              {childElem.name}{" "}
                              {/* <span className="self-center text-[11px] bg-white text-gray-600 rounded-lg px-1 py-0 leading-4">
                                Beta
                              </span> */}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ) : // Check roleType and conditionally render the "Brand" tab
                roleType?.includes("Agency") && curElem?.name !== "Brand" ? (
                  <li key={index} className="relative">
                    <Link
                      to={curElem.url}
                      className="flex items-center text-white gap-x-1"
                      onClick={() => mobileMenu && setMobileMenu(!mobileMenu)}
                    >
                      {curElem.name}
                    </Link>
                  </li>
                ) : roleType?.includes("Freebies") &&
                  curElem?.name === "Brand" ? (
                  <li
                    key={index}
                    onClick={() => showPopup()}
                    className="cursor-pointer relative flex items-center gap-x-1 text-white"
                  >
                    {curElem.name}
                    <HeaderLock
                      left="-right-[18px]"
                      top="top-[3px]"
                      leftPosition="-left-[0px]"
                      topPosititon="top-[38px]"
                      title="Feature Locked!"
                    />
                  </li>
                ) : roleType?.includes("Freebies") &&
                  curElem?.name !== "Brand" ? (
                  <li
                    key={index}
                    className="relative flex items-center gap-x-1 text-white"
                  >
                    {" "}
                    <Link
                      to={curElem.url}
                      className="flex items-center text-white gap-x-1"
                      onClick={() => mobileMenu && setMobileMenu(!mobileMenu)}
                    >
                      {curElem.name}
                    </Link>
                  </li>
                ) : (
                  roleType?.includes("Enterprise") && (
                    <li key={index} className="relative pb-4 lg:pb-0">
                      <Link
                        to={curElem.url}
                        className="flex items-center text-white gap-x-1"
                        onClick={() => mobileMenu && setMobileMenu(!mobileMenu)}
                      >
                        {curElem.name}
                      </Link>
                    </li>
                  )
                )}
              </React.Fragment>
            ))}
            {/* [/ROLE BASED NAVIGATION END]*/}
          </ul>

          <div className="flex leading-none gap-x-5">
            {/* <Link to="Geo Location">
              <span className="text-lg text-white material-icons-outlined">
                settings
              </span>
            </Link> */}
            {/* accountSettings */}
            <Link to="changePassword">
              <span className="text-lg text-white material-icons-outlined">
                apps
              </span>
            </Link>
            <span
              // onClick={() => alert("Notification will be received")}
              className="relative cursor-pointer"
            >
              <span className="text-lg text-white material-icons-outlined">
                notifications
              </span>
              {/* <span className="absolute right-[2px] top-[3px] block h-2 w-2 rounded-full bg-red-500"></span> */}
            </span>

            <span
              //onClick={() => dispatch(logOut(logout_API, authToken))}
              onClick={applogout}
              className="text-lg text-white cursor-pointer material-icons-outlined"
            >
              power_settings_new
            </span>
          </div>
        </div>
        {/* <MorePower
          open={isOpen}
          onClose={() => {
            document.body.classList.remove("overflow-hidden");
            setIsOpen(!isOpen);
          }}
        /> */}
      </header>
    </>
  );
};

export default Header;
